.draggable-container {
  background-color: #f9f9f9; /* Light gray background */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ccc; /* Light border */
  padding: 10px; /* Reduced padding inside the card */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin: 10px auto; /* Reduced margin */
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Space below header */
}

.logo {
  height: 24px; /* Smaller logo size */
  width: 24px; /* Smaller logo size */
  margin-right: 10px; /* Space between logo and description */
}

.description {
  font-size: 14px; /* Font size for description */
  font-weight: bold;
  flex-grow: 1; /* Allow description to take available space */
}

.select-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between elements */
}

.select-group {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between select boxes */
}

.select-container label {
  margin-right: 10px; /* Space between label and select box */
}

.select-container select {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 10px; /* Reduced padding */
  font-size: 14px; /* Slightly smaller font size */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.select-container select:focus {
  border-color: #007bff; /* Highlight border on focus */
  outline: none; /* Remove default outline */
}

.components-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Reduced spacing between components */
}

.draggable-box {
  border: 1px solid #ddd; /* Lighter border */
  padding: 10px; /* Reduced padding */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 180px; /* Adjust width as needed */
  box-sizing: border-box;
}
