* {
  font-family: "Montserrat", sans-serif;
}

.learn-more-header {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
  column-gap: 0;
}

.fast-track-review-rating {
  font-size: 0.875rem;
}

.fast-track-modal {
  padding: 20px 40px;
}

.fast-track-modal-title {
  color: #000;
  font-size: 24px;
  font-weight: 400;
}

.fast-track-modal-sub-title {
  font-size: 14px;
  color: #7c7c7c;
  font-weight: 400;
  margin-top: 12px;
}

.fast-track-modal-body {
  display: flex;
  margin-top: 16px;
  margin-bottom: 24px;
  gap: 1rem;
}

.fast-track-left {
  width: 50%;
}

.fast-track-top {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.fast-track-application-content {
  margin-top: 24px;
}

.fast-track-review {
  display: flex;
  flex-direction: column;
}

.fast-track-review-text {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 400;
}

.fast-track-applicant-detail {
  display: flex;
  align-items: center;
}

.fast-track-item {
  display: flex;
  gap: 4px;
  margin-top: 4px;
}

.fast-track-title {
  color: #0086fc;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}

.fast-track-item-title {
  color: #000000;
  font-weight: 600;
  flex-shrink: 1;
}

.fast-track-status {
  color: #32a852;
  font-weight: bold;
}

.fast-track-applicant-link {
  color: #1d4ed8;
  margin-left: 3px;
}

.fast-track-applicant-risk-tier {
  font-weight: 500;
}

.deep-subprime {
  color: #a83232;
}

.subprime {
  color: #a83232;
}

.near-prime {
  color: #92a832;
}

.prime {
  color: #5ba832;
}

.super-prime {
  color: #32a852;
}

.big-rocket {
  position: fixed;
  width: 160px;
  transform: translate(-60%, -40%);
  z-index: -1;
}
.fast-track-title-with-icon {
  color: #0086fc;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  gap: 5px;
}

.close-icon {
  width: 24px;
  position: fixed;
  margin-top: -24px;
  z-index: 9;
  align-self: end;
  cursor: pointer;
}

.help-icon {
  color: gray;
}

.fast-track-sub-item {
  display: flex;
  margin-left: 1rem;
}

.fast-track-sub-item-title {
  font-weight: 400;
  font-size: 12px;
  color: #747474;
}

.fast-track-sub-item-data {
  font-size: 12px;
  color: #616161;
}

.fast-track-applicant-learn-more {
  color: #3798fe;
  font-size: 12px;
  margin-left: 3px;
}

.fast-track-right {
  width: 50%;
  height: 100%;
}

.default-markup-default-text {
  font-weight: 100;
}

.fast-track-right > fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
}

.default-markup-text-title {
  font-weight: 400;
}

fieldset > legend {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 10.5px;
}

.field-set-title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #0086fc;
}

.fast-track-mark-up-inputs {
  display: flex;
  gap: 1rem;
  width: 98%;
  margin-left: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.customer-pmnt-helper {
  text-align: right;
}

.customer-pmnt-helper-text {
  color: #0c84fc;
}

.default-markup {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.fast-track-modal-wrapper {
  font-family: "Montserrat", sans-serif;
}

.automatically-send-proposals {
  font-weight: 400;
}

.default-markup-link {
  color: #3798fe;
  display: inline;
  font-weight: 400;
}

.fast-track-text {
  font-weight: 700;
  font-style: italic;
}

.fast-track-video-img {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.preview-proposal {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-proposal-text {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #0086fc;
  cursor: pointer;
}

.invoice-total {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.invoice-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-weight: 400;
}

.fast-track-remember-setting {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
}

.finalize-the-deal-fieldset {
  margin-top: 40px !important;
  background-color: #f7f7f7;
  border: 0 none;
  margin: 0;
  padding: 10.5px 0;
}

.finalize-the-deal-fieldset > legend {
  color: #0086fc;
  font-weight: 700;
  padding: 0 16px;
}

.fieldset-content {
  padding: 0 16px;
}

.invoice-action {
  color: #3798fe;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.request-final-doc-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.company-logo {
  width: 75px;
}

.view-proposal {
  font-weight: bold;
  cursor: pointer;
  /* box-shadow: 0 2px 2px 0 rgb(12 132 252 / 14%), 0 3px 1px -2px rgb(12 132 252 / 20%), 0 1px 5px 0 rgb(12 132 252 / 12%); */
  color: #0c84fc;
  margin: 8px;
}

.fast-track-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  right: 55px;
  padding-left: 3px;
}

.fast-track-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.fast-track-item-status {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
